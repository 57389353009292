import "./team.css";
import TeamFirstScreen from "./components/TeamFirstScreen";
import TeamMembersList from "./components/TeamMembersList";
import Footer from "../../components/Footer";

const Team = () => {
    return (
        <main>
            <TeamFirstScreen/>
            <TeamMembersList/>
            <Footer/>
        </main>
    )
}

export default Team;
