import TeamGroupPhoto from "../../../assets/teamGroup.jpg"
import SplineBackground from "../../../components/SplineBackground";
const TeamFirstScreen = () => {
    return (
        <div className={"teamFirstScreen"}>
            <div className="content">
                <div className="text">
                    <h2>We’re Lightblocks</h2>
                    <p>We build infrastructure to connect between smart contract applications and real world data. </p>
                    <p>At Lightblocks, we cultivate an environment that fosters creativity and productivity in pursuit of our missions.</p>
                    <p><strong>As our team expands, we welcome those who share our mission and values.</strong></p>
                </div>
                <div className="image">
                    <img src={TeamGroupPhoto} alt="Our team"/>
                </div>
            </div>
            <SplineBackground/>
        </div>
    )
}

export default TeamFirstScreen
