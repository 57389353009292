import Logo from "../assets/Logo.svg"
import { Link, useLocation } from 'react-router-dom';
import {useEffect, useState} from "react";

const Header = () => {
    const location = useLocation();
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [scrolled, setScrolled] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            setScrolled(currentScrollPos > 100);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);
    return (
        <header className={`${scrolled ? 'top' : ''}`}>
            <div className="content">
                <Link to={"/"}>
                    <img src={Logo} alt="Lightblocks"/>
                </Link>
                <nav>
                    <Link to={"/#"}>
                        Eoracle
                    </Link>
                    <Link to={"/team"} className={` ${location.pathname === '/team' ? 'active' : ''}`}>
                        Team
                    </Link>
                    <Link to={"/#"}>
                        We`re hiring
                    </Link>
                </nav>
            </div>
        </header>
    )
}

export default Header;
