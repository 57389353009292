import "./home.css"
import {Link} from "react-router-dom";
import SplineBackground from "../../components/SplineBackground";
import Footer from "../../components/Footer";

const Home = () => {
    return (
        <main className={"home_page"}>
            <div className="content">
                <p>
                    At <strong>Lightblocks Labs</strong> our mission is to revolutionize blockchain interoperability through shared security
                </p>
                <p>
                    We are committed to developing the <br/>
                    <strong>Eoracle</strong> cross-chain infrastructure as a <i>modular</i> and <i>programmable</i> layer, seamlessly connecting blockchains with real-world data and off-chain computation.
                </p>
                <Link to={"/team"} className="defaultBtn">Meet the Team</Link>
            </div>
            <SplineBackground/>
            <Footer/>
        </main>
    )
}

export default Home;
