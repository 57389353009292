const TeamMember = ({member, index}) => {
    return (
        <div className={"teamMember"} style={{top: 70 + index * 70}}>
            <img src={member.image} alt={member.name} className={"memberImg"}/>
            <div className="mainInfo">
                <p className="name">{member.name}</p>
                <p className="position" dangerouslySetInnerHTML={{__html: `${member.position}`}}/>
            </div>
            <div className="description" dangerouslySetInnerHTML={{__html: `${member.description}`}}/>
            <div className="free"/>
        </div>
    )
}

export default TeamMember;
