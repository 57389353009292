import './App.css';
import { BrowserRouter as Router, Routes ,Route} from 'react-router-dom';
import Header from "./components/Header";
import Home from "./pages/home/Home";
import Team from "./pages/team/Team";
import { ReactLenis } from '@studio-freight/react-lenis'

const App = () => {
  return (
      <Router>
          <div className="App">
              <ReactLenis root>
                  <Header/>
                  <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/team" element={<Team />} />
                  </Routes>
              </ReactLenis>
          </div>
      </Router>
  );
};

export default App;
