import Spline from '@splinetool/react-spline';

const SplineBackground = () => {
    return (
        <div className={"spline"}>
            <Spline scene="https://prod.spline.design/TxgcPUP2oZCAiBMR/scene.splinecode" />
        </div>
    )
}

export default SplineBackground;
