const Footer = () => {
    return (
        <footer>
            <div className="content">
                <p className="rights">
                    ©️ 2024 Lightblocks. All rights reserved.
                </p>
                <div className="social">

                </div>
            </div>
        </footer>
    )
}

export default Footer
