import TeamMember from "./TeamMember";
import Matan from "../../../assets/team/matan.jpeg";
import Uri from "../../../assets/team/uri.jpeg";
import Nimrod from "../../../assets/team/nimrod.jpg";
import Yoni from "../../../assets/team/yoni.jpg";
import Omer from "../../../assets/team/omer.jpg";
import Ittay from "../../../assets/team/ittay.jpg";
import Ron from "../../../assets/team/ron.jpg";
import Yogev from "../../../assets/team/yogev.jpeg";
import Ariel from "../../../assets/team/ariel.jpg";
import Gury from "../../../assets/team/guri.jpg";
import Reef from "../../../assets/team/reef.jpg";
import Nope from "../../../assets/team/nope.webp";
import Tomer from "../../../assets/team/tomer.jpg";
import Francisco from "../../../assets/team/francisco.jpeg";

const TeamMembersList = () => {
    const theTeam = [
        {
            categoryName: "Management",
            items: [
                {
                    image: Matan,
                    name: "Matan Sitbon",
                    position: "CEO",
                    description: "",
                    // description: "" +
                    //     "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse laoreet urna id velit vulputate, ut dignissim justo volutpat. Aliquam erat volutpat.</p>" +
                    //     "<p>Quisque rhoncus, odio eget porta condimentum, lectus sapien interdum urna, vitae fringilla ante felis quis ante. Nulla facilisi. Praesent fermentum tristique turpis.</p>"
                },
                {
                    image: Uri,
                    name: "Uri Margalit",
                    position: "VP <br> Research & Development",
                    description: "",
                    // description: "" +
                    //     "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse laoreet urna id velit vulputate, ut dignissim justo volutpat. Aliquam erat volutpat.</p>" +
                    //     "<p>Quisque rhoncus, odio eget porta condimentum, lectus sapien interdum urna, vitae fringilla ante felis quis ante. Nulla facilisi. Praesent fermentum tristique turpis.</p>"
                },
                {
                    image: Nimrod,
                    name: "Nimrod Cohen",
                    position: "Head of Product & Research",
                    description: ""
                },
                {
                    image: Yoni,
                    name: "Yoni Keselbrener",
                    position: "Senior Blockchain Software Engineer",
                    description: ""
                },
                {
                    image: Omer,
                    name: "Prof. Omer Ben-Porat",
                    position: "Research Scientist",
                    description: ""
                },
                {
                    image: Ittay,
                    name: "Prof. Ittay Eyal",
                    position: "Research Scientist",
                    description: ""
                },
                {
                    image: Ron,
                    name: "Ron Turetzky",
                    position: "Blockchain Developer",
                    description: ""
                },
                {
                    image: Yogev,
                    name: "Yogev Bar On",
                    position: "Researcher",
                    description: ""
                },
                {
                    image: Ariel,
                    name: "Ariel Laub",
                    position: "Senior Blockchain architect",
                    description: ""
                },
                {
                    image: Gury,
                    name: "Gury Traub",
                    position: "Senior blockchain architect",
                    description: ""
                },
                {
                    image: Reef,
                    name: "Reef Naaman",
                    position: "Marketing & Business Development",
                    description: ""
                },
                {
                    image: Nope,
                    name: "Yonatan Hauyn",
                    position: "Technical Advisor",
                    description: ""
                },
                {
                    image: Tomer,
                    name: "Tomer Brandes",
                    position: "Token Architect",
                    description: ""
                },
                {
                    image: Francisco,
                    name: "Francisco Baralle",
                    position: "Blockchain Dev",
                    description: ""
                },
            ]
        }
    ]
    return (
        <div className={"teamMembers content"}>
            {
                theTeam.map((cat,i) => (
                    <div key={i}>
                        {/*<h3>{cat.categoryName}</h3>*/}
                        {
                            cat.items.map((person, j) => (
                                <TeamMember member={person} index={j} key={j}/>
                            ))
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default TeamMembersList;
